.toggle-label {
    width: 100%;
    height: 100%;
    display: grid;
}
.toggle {
    --width: 30px;
    --height: calc(var(--width) / 2);
    --border-radius: calc(var(--height) / 2);  
    display: inline-block;
    place-self: center;
}
.toggle-input {
    display: none;
}
.toggle-fill {
    position: relative;
    width: var(--width);
    height: var(--height);
    border-radius: var(--border-radius);
    background:  var(--card-background);
    transition: background 0.2s;
    border: solid 1px var(--border-color);
}   
.toggle-fill::after {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    height: var(--height);
    width: var(--height);
    background: var(--foreground);
    border-radius: var(--border-radius);
    transition: transform 0.2s;
}  
.toggle-input:checked ~ .toggle-fill::after {
    transform: translateX(var(--height));
}
  