.modal {
    float: right;
    width: 100%;
    position: relative;
    z-index: 2;
    background: white;
    border-left: solid 1px var(--border-color);
    overflow-y: auto;
    max-width: 480px;
}
.darkmode .modal {
    background: #111;
}
  