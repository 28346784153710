.linear-progress {
    width: 100%;
    height: 4px;
    background: var(--primary-color);
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.linear-progress::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--primary-color-hover);
    animation: progress 2s linear infinite;
}
@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
