@font-face {
  font-family: 'Roboto'; 
  font-weight: 300; 
  src: url('./fonts/roboto-light-webfont.woff2') format(woff2),
       url('./fonts/roboto-light-webfont.woff') format(woff);
  font-display: swap;
}

* {    
  font-family: 'Roboto', sans-serif;  
  margin: 0;
  padding: 0;
  box-sizing: border-box;  
  letter-spacing: 0.09em;   
  caret-color: transparent;
}

:root {
  --primary-color: #fd961a;
  --primary-color-hover: #fcb967;
  --cta-green: #13ae4b;

  
  --active-table-row: #77dd77;
  --active-table-row-hover: #98FF98;
  
  --maintenance-table-row: orange;
  --maintenance-table-row-hover: #F28C28;

  --graveyard-table-row: #ccc;
  --graveyard-table-row-hover: #eee;

  --migrated-table-row: #bbb;
  --migrated-table-row-hover: #ddd;

  --monitored-table-row: #50C878;
  --monitored-table-row-hover: #77dd77;

  --cta-red: red;
  --speed: 300ms;  
  --box-shadow: 0 1px 18px 5px #00000017;
  --foreground: #222;
  --background: #F8F8F8;
  --card-background: #fff;
  --border-color: #eee;  
  --reverse-border-color: #444;     
  --button-disabled-background: #ddd;  
  --button-disabled-color: #bbb;  
  --button-text-color: #fff;
  --input-background-color: #F8F8F8;
  --icon-button-background: rgba(0,0,0,0.03);
  --icon-button-background-hover: rgba(0,0,0,0.05);
  --icon-color: #111;
  --icon-color-hover: #999;
  --tooltip-background: #333;
  --tooltip-text-color: #fff;
  --table-section-background: #ffffff;  
  --alternate-rows: #f9f9f9;
  --hidden-row: #eee;
  --table-row-open: #555;
}

.darkmode {   
  --foreground: #ccc;
  --background: #333;
  --card-background: #111;
  --border-color: #444;  
  --reverse-border-color: #eee;  
  --button-disabled-background: #222;
  --button-disabled-color: #555;
  --button-text-color: #fff;
  --input-background-color: #444;
  --icon-button-background: #2b2b2b;
  --icon-button-background-hover: #333;
  --icon-color: var(--primary-color);
  --icon-color-hover: var(--primary-color-hover);
  --tooltip-background: #f1f2f6;
  --tooltip-text-color: #222;
  --table-section-background: rgba(255,255,255,0.1);  
  --alternate-rows: #222;
  --hidden-row: #545454;
  --table-row-open: #fff;
}

/* Layout Styles */
body {
  background: var(--background);
  color: var(--foreground);   
}
.background-panel {
  background: var(--card-background);
}
#modal-root {
  width: 100%;
}
#root {
  display: flex; 
  flex-direction: column;    
  min-height: 100vh;  
}
main {
  flex-grow: 1;  
  position: relative;     
}
.container {
  max-width: 70vw;   
  margin: auto;
}
.grid {
  display: flex;
}
.grid-sidebar {
  flex: 1 0 100px;
  padding: 40px 20px;
}
.grid-main {
  flex: 1 1 1366px;
}

@media ( max-width: 980px ) {     
  .grid {
    flex-direction: column;
  }
  .grid-sidebar {
    padding: 40px;
  }
}
@media ( max-width: 768px ) {  
  .grid-sidebar {
    padding: 30px;
  }
}

@media ( max-width: 1366px ) {     
  .container {
    max-width: none;   
  }
}
.wrapper {
  width: 100%; 
  padding: 40px;  
}
.parent {
  display: flex;
  justify-content: space-between;
} 

/* Element Styles */
h1 {
  margin-bottom: 40px;
}
h2 {
  margin-bottom: 40px
}
h3 {
  margin-bottom: 40px;
  text-align: center;
}
h6 {
  color: #777;
}

p {
  font-size: 14px;
  line-height: 1.8rem;
}

small {
  font-size: 12px;
}

sub {
  font-size: 12px;
  align-self: flex-end;
  font-weight: 100;
}
sup {
  font-size: 12px;
  align-self: flex-start;
}

ul {
  list-style: none;
}
ol {
  padding-left: 20px;
  margin: 10px 0;
}
li {
  font-size: 14px;
  line-height: 1.8em;
}

a, .link {
  color: var(--icon-color);
  text-decoration: none;
  transition: all var(--speed);
  font-size: 12px;
}

a:hover, .link:hover {
  color: var(--primary-color);
}

button {
  border: none;
  outline: none;
  box-shadow: none;
}

button[disabled] {
  pointer-events: none;
} 

button[disabled] .icon {
  color: #999 !important;
} 

img {    
  height: auto; 
  display: inline-block;  
} 
.object-fit {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 12px; 
  height: 12px;
}  
::-webkit-scrollbar-track-piece { /* background */
  background: var(--background);  
} 
::-webkit-scrollbar-button { /* corners */
  background:var(--background);  
}
::-webkit-scrollbar-thumb {
  background: var(--icon-color); 
  border-radius: 10px; 
  border: solid 3px var(--background);
}
::-webkit-scrollbar-thumb:hover {
  background: #999; 
  opacity: 0.5;
}

/* Helper (BootStrap) Classes */
.d-flex {
  display: flex;
}
.gap-1 {
  gap: 10px;
}
.gap-2 {
  gap: 20px;
}
.gap-4 {
  gap: 40px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.direction-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
  top: 0;
  left: 0;
}
.inline-block {
  display: inline-block;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.cursor-pointer {
  cursor: pointer;
}
.zoom-in {
  cursor: zoom-in;
}
.scroll-x {
  overflow-x: auto;
}
.scroll-y {
  overflow-y: auto;
}
.hidden {
  display: none;
}
.screenheight {
  height: 100vh;
}

/* Button  styles */
.pointer-events-none {
  pointer-events: none;
  opacity: .3;
}
button {
  outline: none;
  border: none;
  text-transform: uppercase;
  transition: all var(--speed);
  cursor: pointer;
  background: none;
  display: flex;
  align-items: center;    
  font-size: 1rem;
}
button[disabled] {
  pointer-events: none;
  background: var(--button-disabled-background);
  color: var(--button-disabled-color);
  box-shadow: none;
} 
.button {
  justify-content: center;
  box-shadow: 
  0px 3px 1px -2px rgb(0 0 0 / 20%), 
  0px 2px 2px 0px rgb(0 0 0 / 14%), 
  0px 1px 5px 0px rgb(0 0 0 / 12%);
  background-position: center;
  transition: background 0.8s, box-shadow var(--speed);
  padding: 40px 18px;
  color: var(--button-text-color);
  background: var(--primary-color);
  outline: none;
  width: 100%;    
}
.button:hover {
  box-shadow: 
  0px 2px 4px -1px rgb(0 0 0 / 20%), 
  0px 4px 5px 0px rgb(0 0 0 / 14%), 
  0px 1px 10px 0px rgb(0 0 0 / 12%); 
  background: var(--primary-color-hover) radial-gradient(circle, transparent 1%, var(--primary-color-hover) 1%) center/15000%;
  color: white;
}
.button:active {
  box-shadow: 
  0px 5px 5px -3px rgb(0 0 0 / 20%), 
  0px 8px 10px 1px rgb(0 0 0 / 14%), 
  0px 3px 14px 2px rgb(0 0 0 / 12%);
  background-color: var(--primary-color);
  background-size: 100%;
  transition: all 0s;
  color: white;
}

/* Icon button styles */
.icon-button {
  display: flex;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: var(--icon-button-background);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  align-items: center; 
}
.icon-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  opacity: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  pointer-events: none;
}  
.icon-button:active::after {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}
.icon-button:hover {
  background: var(--icon-button-background-hover);
}
.icon-button:hover .icon {
  transform: scale(1.2);
} 

/* Avatar styles */
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  background: #999;
  border-radius: 50%;
  border: solid 1px var(--border-color);
}

/* Icon styles */
.icon {
  color: var(--icon-color);
  transition: all var(--speed);
  width: 1em;
  height: 1em;
  fill: currentColor;
  font-size: 1.5rem;
}
.icon:hover,
.icon-button:hover .icon {
  color: var(--icon-color-hover);
}

/* Tab styles */
.tabs {
  display: flex;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
.tabs button {
  background-color: var(--card-background);
  color: var(--foreground);  
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.tabs button.active {
  border-bottom: solid 2px var(--primary-color);
}

@media ( max-width: 768px ) {  
  .wrapper {
    padding: 30px;
  }
  .parent {
    flex-direction: column;
    align-items: center;
  } 
  .column-reverse {
    flex-direction: column-reverse;
   }
}

@media ( max-width: 501px ) {     
  .wrapper {
    padding: 30px 20px;   
  }
}
