.path {  
    stroke-width: 1;  
    stroke-linecap: round;                 
    fill: none;  
    stroke: var(--icon-color);
} 

.draw {
    stroke-dasharray: 628.5596313476562; 
    animation: draw 2.5s ease-in-out infinite;
}

@keyframes draw {
    0% {
        stroke-dashoffset: 628.5596313476562; 
        opacity: 0;                    
    }
    15% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        stroke-dashoffset: 0;
        opacity: 0;                    
    }
}