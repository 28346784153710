/* Tables */
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}
th,
td {
    font-size: 12px; 
    vertical-align: middle;
    padding: 2px 5px;
    text-align: left; 
    white-space: nowrap;   
    transition: all var(--speed);
} 
.data-table th {
    position: sticky;
    top: -1px;
    z-index: 2;
    text-align: left;
    background: var(--background);
    box-shadow: var(--box-shadow);
    padding: 20px 10px;
    text-transform: uppercase;    
    cursor: pointer;   
}
.data-table td {
    border-bottom: solid 1px var(--border-color);
    padding: 2px 10px;
    color: var(--foreground);
}
.data-table thead {
    box-shadow: var(--box-shadow);
    border-bottom: solid 1px var(--border-color);
}
.data-table img {
    display: block;
    margin: auto;
}
.hidden-row td {
    height: 50px;
}
.data-table-wrapper {
    overflow: auto;
    border-top: solid 1px var(--border-color);
    border-bottom: solid 1px var(--border-color);
    max-height: 800px;
}
.data-table {
    border-left: solid 1px var(--border-color);
    border-right: solid 1px var(--border-color);
}
.table-row {
    transition: background var(--speed);
} 
.table-row:last-child .tooltip {
    bottom: 0;
    top: auto;
}
.table-row:nth-child(2n) {
    background: var(--alternate-rows);
}
.table-row.open,
.table-row:hover,
.data-table th:hover,
.data-table td.highlighted-column {
    background: var(--table-row-open);
    color: var(--background) !important;
    border-color: var(--reverse-border-color) !important;
}

.data-table tr.active-state {
    background: var(--active-table-row);
}
.data-table tr.active-state:hover {
    background: var(--active-table-row-hover);
}

.data-table tr.monitored-state {
    background: var(--monitored-table-row);
}
.data-table tr.monitored-state:hover {
    background: var(--monitored-table-row-hover);
}

.data-table tr.maintenance-state {
    background: var(--maintenance-table-row);
}
.data-table tr.maintenance-state:hover {
    background: var(--maintenance-table-row-hover);
}

.data-table tr.graveyard-state {
    background: var(--graveyard-table-row);
}
.data-table tr.graveyard-state:hover {
    background: var(--graveyard-table-row-hover);
}

.data-table tr.migrated-state {
    background: var(--migrated-table-row);
}
.data-table tr.migrated-state:hover {
    background: var(--migrated-table-row-hover);
}

.data-table tr.active-state td,
.data-table tr.maintenance-state td,
.data-table tr.graveyard-state td,
.data-table tr.migrated-state td,
.data-table tr.monitored-state td {
    color: black;
}

.data-table th:hover {
    color: var(--primary-color) !important;
}
/* .table-row.open a,
.table-row:hover a,
.table-row.open svg,
.table-row:hover svg,
.highlighted-column a,
.highlighted-column svg,
.data-table th:hover {
    color: var(--background);
} */
.table-row:hover .icon-button {
    background: transparent;
}
.data-table-container button[disabled] {
    opacity: .2;
}
.hidden-row {
    background: var(--hidden-row);
}
.hidden-row div {
    display: flex;
    align-items: center;
}
.hidden-row td {
    color: var(--foreground);
}
.colls ul {
    flex: 1;
}
.colls li {
    margin-bottom: 10px;
}
.colls ul li:first-child {
    background: none;
    text-indent: 0;
    color: var(--primary-color);
    font-weight: bold;
} 