/* form styles */
.user-form {    
    padding: 40px;
    width: 100%;
    max-width: 480px;
    border-radius: 5px;
    margin: 0 auto;
    border: solid 1px var(--border-color);  
}

.user-form a,
.user-form .link {
    color: #999;
}
.user-form section {
    padding: 20px;
    background: var(--table-section-background);
    border: solid 1px var(--border-color);
}
.input-link {
    position: absolute;
    top: -2px;
    left: auto; 
    right: -5px;
}

/* Input styles */
input,
textarea {
    outline: none;
    border: none;
    background: none;
    caret-color: black;
    color: var(--icon-color);
    border: solid 1px var(--border-color);
    text-indent: 6px;
    width: 100%;
    caret-color: var(--icon-color);
}
.input {    
    font-size: 14px;
    border-bottom: solid 1px var(--border-color);
    padding: 10px;
    background: var(--input-background-color);
    transition: border-color var(--speed);    
    caret-color: var(--icon-color);
}
.input:hover {
    border-color: var(--primary-color);
}
.input:focus {
    border-color: var(--primary-color);
}  

/* placeholder styles */
.input::placeholder {
    color: var(--primary-color); 
    transition: all var(--speed);
    font-size: 14px;
    opacity: 0;
}
.input:focus::placeholder {
    font-size: 0;
}

/* Icon styles */
.adornment-end {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
}

/* label styles */ 
.label {
    position: relative;
    display: flex;  
    flex: 1 0 100px;
    color: var(--primary-color);
}
.label::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    border-bottom: 1px solid #999;
    transition: all var(--speed);
}  
.label:focus-within::before {
    width: 100%;
    border-color: var(--primary-color);
}

/* small styles */
.label small {
    position: absolute;
    /* top: 10px;
    left: 10px;
    opacity: 0; */
    left: 0;
    top: -16px;
    opacity: 1;
    transition: all var(--speed) linear;
}
.label .input:focus + small {
    font-size: 10px;
    left: 0;
    top: -16px;
    opacity: 1;
}

/* Select styles */
.select-dropdown {    
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;  
    background: var(--input-background-color);
    z-index: 10;
    overflow-y: auto;   
    box-shadow: var(--box-shadow);   
}
.select-dropdown.modal-input {
    position: fixed;
    width: auto;
    top: 0;
}
.select-dropdown li {
    padding: 10px;
    border: solid 1px var(--border-color);
    border-top: none;
    cursor: pointer;
    background: var(--select-background-color);
    transition: all var(--speed);
    color: var(--icon-color);     
}
.select-dropdown li .select-label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.select-dropdown li:hover {
    background: var(--select-background-color-hover);
}
.select-container input {
    min-height: 63px;
}

/* Radio styles */
.radio-button-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: none;
    border-radius: 50%;
    background-position: center;
    transition: background 0.5s, box-shadow var(--speed);   
    background-color: none;
    cursor: pointer;
}
.radio-button-container:hover {
    background: 
    var(--icon-button-background-hover) 
    radial-gradient(circle, transparent 1%, var(--icon-button-background-hover) 1%) center/15000%;
}
.radio-button-container:active {
    background-color: var(--icon-button-background);
    background-size: 100%;
    transition: all 0s;
}
.radio-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: solid 2px var(--icon-color);
    background: var(--background);
    border-radius: 50%;
    transition: all var(--speed);
}
.radio-button:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: none;
    border-radius: 50%;
}
.radio-button-container:hover .radio-button,
.radio-button-container:hover .radio-button.checked,
.radio-button-container:hover .radio-button.checked:after,
.radio-button-container:active .radio-button{
    border-color: var(--icon-color-hover);
}
.radio-button-container:active .radio-button:after {
    background: var(--icon-color-hover);
}
.radio-button.checked {
    border-color: var(--icon-color);
}
.radio-button.checked:after {
    background: var(--icon-color);
}

.radio-button-container.active-state .radio-button {
    border: solid 2px var(--active-table-row);
}
.radio-button-container.active-state:hover .radio-button,
.radio-button-container.active-state:hover .radio-button.checked,
.radio-button-container.active-state:hover .radio-button.checked:after,
.radio-button-container.active-state:active .radio-button{
    border-color: var(--active-table-row-hover);
}
.radio-button-container.active-state .radio-button.checked:after {
    background: var(--active-table-row);
}
.radio-button-container.active-state:active .radio-button:after {
    background: var(--active-table-row-hover);
}

.radio-button-container.maintenance-state .radio-button {
    border: solid 2px var(--maintenance-table-row);
}
.radio-button-container.maintenance-state:hover .radio-button,
.radio-button-container.maintenance-state:hover .radio-button.checked,
.radio-button-container.maintenance-state:hover .radio-button.checked:after,
.radio-button-container.maintenance-state:active .radio-button{
    border-color: var(--maintenance-table-row-hover);
}
.radio-button-container.maintenance-state .radio-button.checked:after {
    background: var(--maintenance-table-row);
}
.radio-button-container.maintenance-state:active .radio-button:after {
    background: var(--maintenance-table-row-hover);
}

.radio-button-container.graveyard-state .radio-button {
    border: solid 2px var(--graveyard-table-row);
}
.radio-button-container.graveyard-state:hover .radio-button,
.radio-button-container.graveyard-state:hover .radio-button.checked,
.radio-button-container.graveyard-state:hover .radio-button.checked:after,
.radio-button-container.graveyard-state:active .radio-button{
    border-color: var(--graveyard-table-row-hover);
}
.radio-button-container.graveyard-state .radio-button.checked:after {
    background: var(--graveyard-table-row);
}
.radio-button-container.graveyard-state:active .radio-button:after {
    background: var(--graveyard-table-row-hover);
}

.radio-button-container.migrated-state .radio-button {
    border: solid 2px var(--migrated-table-row);
}
.radio-button-container.migrated-state:hover .radio-button,
.radio-button-container.migrated-state:hover .radio-button.checked,
.radio-button-container.migrated-state:hover .radio-button.checked:after,
.radio-button-container.migrated-state:active .radio-button{
    border-color: var(--migrated-table-row-hover);
}
.radio-button-container.migrated-state .radio-button.checked:after {
    background: var(--migrated-table-row);
}
.radio-button-container.migrated-state:active .radio-button:after {
    background: var(--migrated-table-row-hover);
}

/* Login form styles */
.login-form .button {
    padding: 20px;
}
.login-form > div > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    gap: 20px;
}
.login-form > div > div:first-child button {
    justify-content: flex-start;
    gap: 10px;
}
.login-form > div > div:first-child button:last-child {
    margin-bottom: 0;
}

.button.google{
    background-color: #df4930;
    text-transform: none;
}
.button.google:hover {
    background: #fa5236 radial-gradient(circle, transparent 1%, #fa5236 1%) center/15000%;
}
.button.google:active {    
    background-color: #df4930;
    background-size: 100%;
}

.button.facebook{
    background-color: #507cc0;
    text-transform: none;
}
.button.facebook:hover {
    background: #6398ea radial-gradient(circle, transparent 1%, #6398ea 1%) center/15000%;
}
.button.facebook:active {    
    background-color: #507cc0;
    background-size: 100%;
}

.button.discord {
    background-color: #5865F2;
    text-transform: none;
}
.button.discord:hover {
    background: #838df4 radial-gradient(circle, transparent 1%, #838df4 1%) center/15000%;
}
.button.discord:active {    
    background-color: #5865F2;
    background-size: 100%;
}

.button.github{
    background-color: black;
    border: solid 1px #444;
    text-transform: none;
}
.button.github:hover {
    background: #555 radial-gradient(circle, transparent 1%, #555 1%) center/15000%;
}
.button.github:active {    
    background-color: black;
    background-size: 100%;
}

.button.twitter{
    background-color: #39aeef;
    text-transform: none;
}
.button.twitter:hover {
    background: #61c4fb radial-gradient(circle, transparent 1%, #61c4fb 1%) center/15000%;
}
.button.twitter:active {    
    background-color: #39aeef;
    background-size: 100%;
}

.divider {
    border: solid 1px var(--border-color);
    flex: 0;
}
.divider:after {
    content: 'OR';
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    border: solid 1px var(--border-color);
    background: var(--background);
    border-radius: 50%;
    padding: 10px;
}

/* checkbox */
.checkbox {
    height: 25px;
    width: 25px;
    background: var(--card-background);
    border: solid 1px var(--border-color);
    border-radius: 5px; 
    cursor: pointer;
}
.checkbox input {    
    display: none;
}

@media ( max-width: 768px ) {  
    .user-form .input {
        width: 100%;
    }
    .label,
    .radio-label {
        display: block;    
        width: 100%;
        flex: auto;
    }
    .login-form > div > div {
        width: 100%;
    }
    .login-form .divider {
        width: 100%;
    }
    .divider:after {       
        top: 50%; 
        left: 50%;
        transform: none;
        transform: translateX(-50%);       
    }
    .divider.sign-up:after {
        top: 56%;
    }
}