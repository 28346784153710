.tooltip-container {
    position: relative;
    display: inline-block;
    margin: auto;
  }  
  .tooltip {
    position: absolute;   
    top: calc(100% + 5px); 
    background: var(--tooltip-background);
    color: var(--tooltip-text-color);
    padding: 4px 8px;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
    z-index: 1;
    font-size: 12px;
  }  
  .tooltip-container:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }  