header {
    position: sticky;
    top: 0;
    z-index: 3;
    border-bottom: solid 1px var(--border-color);
}

.logo {
    background: var(--background);
    border: solid 1px var(--border-color);
    margin: 10px;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 58px;
}
  
header .dropdown-menu {  
    position: absolute;
    top: calc(100% + 19px);
    right: 0;
    gap: 0.7rem;
    z-index: 2000;
    box-shadow: var(--box-shadow);
    background: var(--background);
}

header .dropdown-menu a {   
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px;
    white-space: nowrap;
} 